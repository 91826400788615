import React from 'react'
import { StyledButtonRounded, SytledDotButton } from './style'
import { Typography } from 'vlab-frontend-components';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
type Props = {
  currentPage: number
  totalPages: number
  pageSize: number
  labelRowsPerPage?: string
  total: number
  disabled?: boolean
  onPageChange?: (value: number) => void
  onRowsPerPageChange?: (value: number) => void
  rowsPerPageOptions?: Array<number>
};

export default function Pagination({
  currentPage,
  totalPages,
  pageSize,
  disabled,
  total,
  onPageChange,
}: Props) {
  const page = currentPage + 1

  const handlePageChange = (page: number) => {
    if (onPageChange) {
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    }
  };


  const renderPageButtons = () => {
    const visiblePageCount = window.innerWidth < 620 ? 1 : 10;
    const startPage = Math.max(1, page - Math.floor(visiblePageCount / 2));
    const endPage = Math.min(totalPages, startPage + visiblePageCount - 1);


    const buttons: React.ReactNode[] = [];

    if (startPage > 1) {
      buttons.push(<SytledDotButton key="ellipsis-left" disabled={disabled}>...</SytledDotButton>);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <StyledButtonRounded
          data-testid={`index-button-${i}`}
          key={i}
          style={{ backgroundColor: i === page ? '#ECF2F8' : 'transparent' }}
          onClick={() => handlePageChange(i)}
          disabled={disabled}
        >
          {i}
        </StyledButtonRounded>
      );
    }

    if (endPage < totalPages && totalPages - endPage > 1) {
      buttons.push(<SytledDotButton key="ellipsis-right" disabled={disabled}>...</SytledDotButton>);
    }

    if (endPage < totalPages) {
      buttons.push(
        <StyledButtonRounded
          data-testid={`index-button-${totalPages}`}
          key={totalPages}
          style={{ backgroundColor: totalPages === page ? '#ECF2F8' : 'transparent' }}
          onClick={() => handlePageChange(totalPages)}
          disabled={disabled}
        >
          {totalPages}
        </StyledButtonRounded>
      );
    }

    return buttons;
  };

  return (

    <div
      data-testid="pagination-container"
      style={{
        backgroundColor: '#F7F8FC',
        width: '100%',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        marginTop: "16px",
        flexWrap: 'wrap'
      }}
    >
      <Typography type='poppinsBodyS' color='greyDark' style={{ fontSize: 10 }}>{pageSize} resultados por página</Typography>
      <div
        data-testid="pagination"
        style={{
          width: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 0',
          gap: 24,
        }}
      >
        <StyledButtonRounded disabled={disabled} data-testid="previous-button" onClick={() => handlePageChange(page - 1)}>
          <IoIosArrowBack />
        </StyledButtonRounded>

        <div style={{flexWrap: 'wrap', display: 'flex', alignItems: 'center', gap: 8 }}>{renderPageButtons()}</div>

        <StyledButtonRounded disabled={disabled} data-testid="next-button" onClick={() => handlePageChange(page + 1)}>
          <IoIosArrowForward />
        </StyledButtonRounded>
      </div>

      <Typography type='poppinsBodyS' color='greyDark' style={{ fontSize: 10 }}>{total} resultados</Typography>
    </div>
  );
};


