import { ExpandedImage, Gallery, ImageCarousel } from "vlab-frontend-components"

interface MomentGalleryProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  listOfMedia: {
    thumb: string;
    type: string;
  }[]
  selectedMoment: {
    thumb: string;
    index: number;
    type: string;
    src: string;
  };
  setSelectedMoment: (selectedScreenShot: any) => void;
}

export default function MomentsGallery({ open, setOpen, selectedMoment, setSelectedMoment, listOfMedia }: MomentGalleryProps) {

  return (
    <Gallery isOpen={open} onClose={() => setOpen(false)}>
      {selectedMoment?.type === 'video' ? (
        <>
          <video
            src={selectedMoment?.src}
            controls
            style={{ width: '100%', height: '75%' }}
            autoPlay
          />
        </>
      ) : (
        <ExpandedImage src={selectedMoment?.thumb ? selectedMoment.thumb : ''} />
      )}

      <ImageCarousel
        //@ts-ignore
        thumbs={listOfMedia}
        //@ts-ignore
        selected={selectedMoment}
        //@ts-ignore
        onSelected={(value) => setSelectedMoment(value)}
        onNext={(index) => {
          setSelectedMoment({ ...listOfMedia[index + 1], index: index + 1 })
        }}
        onPrevious={(index) => {
          setSelectedMoment({ ...listOfMedia[index - 1], index: index - 1 })
        }}
      />
    </Gallery>
  )
}