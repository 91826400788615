import { Typography, ArrowUpIcon, ArrowDownIcon, } from "vlab-frontend-components";
import { AccordionContainer, AccordionHeader, AccordionContent, AccordionDetails } from "./style";

interface AccordionProps {
  setOpenAccordion: (value: boolean) => void
  openAccordion: boolean
  children?: React.ReactNode
  title: string
}

export default function Accordion({ openAccordion, setOpenAccordion, children, title }: AccordionProps) {

  return (
    <AccordionContainer>
      <AccordionHeader onClick={() => setOpenAccordion(!openAccordion)}>
        <Typography color="blackLight" type="poppinsBodyS">{title}</Typography>
        <div style={{ width: 16, height: 16 }}>
          {openAccordion ? <ArrowUpIcon color='#51525F' /> : <ArrowDownIcon color='#51525F' />}
        </div>
      </AccordionHeader>
      <AccordionContent isOpen={openAccordion}>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </AccordionContent>
    </AccordionContainer>
  )
}