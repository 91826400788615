import { createHashRouter } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './components/Layout';
import Exams from './pages/Exams';
import Report from './pages/Report';

export const router = createHashRouter([
  {
    path: '/*',
    element: <Login />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: '/exams',
        element: <Exams />,
      },
      {
        path: '/medical-reports',
        element: <Report />,
      },
    ],
  },
]);
