import ReactDOM from 'react-dom/client'
import './index.css'
import { FrontendComponentsThemeProvider, Notistack } from 'vlab-frontend-components'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'

//Mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      onError: (error: unknown) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            console.log('entrou aqui')
            localStorage.clear()
            window.location.href = '/'
          }
        }
      },
    },
    mutations: {
      onError: (error: unknown) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            localStorage.clear()
            window.location.href = '/'
          }
        }
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <FrontendComponentsThemeProvider themeMode="vlab">
    <Notistack.SnackbarProvider maxSnack={2}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Notistack.SnackbarProvider>
  </FrontendComponentsThemeProvider>
)
