import { useAtom } from 'jotai'
import { GreetingByTimeCard } from './style'
import { clientConfigAtom } from '../../pages/Login'
import { Typography } from 'vlab-frontend-components'
import FlexContainer from '../Flex'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export default function GreetingByTime() {
  const [clientConfig] = useAtom(clientConfigAtom)

  function greetingByTimeOfDay() {
    const now = new Date()
    const hour = now.getHours()

    if (hour >= 6 && hour < 12) {
      return 'Bom dia!'
    } else if (hour >= 12 && hour < 18) {
      return 'Boa tarde!'
    } else {
      return 'Boa noite! '
    }
  }

  function formatDate() {
    const today = new Date()
    let formattedDate = format(today, 'EEE, dd/MMM', { locale: ptBR })

    formattedDate = formattedDate
      .split(' ')
      .map((parte) => parte.charAt(0).toUpperCase() + parte.slice(1))
      .join(' ')

    return formattedDate
  }

  return (
    <GreetingByTimeCard background={clientConfig.patient_portal_settings.menu.menuItemBackground}>
      <Typography color="white" type="headingL3">
        {greetingByTimeOfDay()} <span style={{ fontSize: '26px' }}>&#128075;</span>
      </Typography>

      <FlexContainer direction="column" alignItems="flex-end">
        <Typography
          color="white"
          type="headingL5"
          style={{
            fontWeight: 400,
            color: clientConfig.patient_portal_settings.loginButton.textColor,
          }}
        >
          {formatDate()}
        </Typography>
        <Typography color="white" type="headingL4" style={{ fontWeight: 400 }}>
          {format(new Date(), 'HH:mm')}
        </Typography>
      </FlexContainer>
    </GreetingByTimeCard>
  )
}
