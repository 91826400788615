import { ActionIconButton, DownloadIcon, GridItem, Typography } from 'vlab-frontend-components'
import Accordion from '../../../../components/Accordion'
import { useApi } from '../../../../hooks/useAPi'
import { useMixpanel } from '../../../../hooks/useMixpanel'

export default function AccordionActions({ open, setOpen, selectedExam }) {
  const { getMp4Exam } = useApi()
  const { trackEvent } = useMixpanel()

  return (
    <Accordion openAccordion={open} setOpenAccordion={setOpen} title="Ações">
      <>
        <GridItem>
          <ActionIconButton
            variant="tertiary"
            onClick={() => {
              trackEvent("WEB_PATIENT_DOWNLOADEXAM", {
                examId: selectedExam.exam,
                isVlabStaff: selectedExam.organization_code.toLocaleLowerCase() === 'vlab'
                
              })

              getMp4Exam(selectedExam?.exam || null)}
            }
          >
            <DownloadIcon />
            <Typography type="poppinsBodyS" color="greyDark" style={{ fontWeight: 600 }}>
              Baixar video
            </Typography>
          </ActionIconButton>
        </GridItem>
      </>
    </Accordion>
  )
}
