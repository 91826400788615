import mixpanel from "mixpanel-browser";

const { REACT_APP_ENV } = process.env


type EventType =
  "WEB_PATIENT_LOGIN" |
  "WEB_PATIENT_LISTEXAMS" |
  "WEB_PATIENT_LISTRESULTS" |
  "WEB_PATIENT_PLAYEXAM" |
  "WEB_PATIENT_DOWNLOADEXAM" |
  "WEB_PATIENT_LISTSCREENSHOT" |
  "WEB_PATIENT_BESTMOMENTS" |
  "WEB_PATIENT_ERROPLAYVIDEO" |
  "WEB_PATIENT_DOWNLOADREPORT"


export function useMixpanel() {
  const identify = (cellphone: string, cpf: string, pin: string) => {
    if (REACT_APP_ENV === 'production') {
      mixpanel.identify(cellphone)

      mixpanel.people.set(cellphone, {
        'cpf': cpf,
        'pin': pin,
        'cellphone': cellphone,
      })
    }
  }

  const trackEvent = (eventType: EventType, data: any) => {
    if (REACT_APP_ENV === 'production') {
      mixpanel.track(eventType, data)
    }
  }

  const reset = () => {
    if (REACT_APP_ENV === 'production') {
      mixpanel.reset()
    }
  }

  return { identify, trackEvent, reset };
}

