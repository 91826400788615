import styled from "styled-components"

interface GreetingByTimeCardProps {
  background?: string
}

export const GreetingByTimeCard = styled.div<GreetingByTimeCardProps>` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background-color: ${({ background }) => background ? background : '#0145D5'};
  height: 89px;
  border-radius: 8px;
  padding: 0px 24px;
  width: 100%;
  @media screen and (max-width: 485px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    height: auto;
    & > div {
      align-items: center;
    }
    padding: 24px;
  }
  
`
