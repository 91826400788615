import styled, { css, keyframes } from 'styled-components'

export const AccordionContainer = styled.div`
  margin-top: 24px;
  background-color: #ecf2f8;
  border-top: 1px solid #dde5ec;
  border-bottom: 1px solid #dde5ec;
`

export const AccordionHeader = styled.div<{ open?: boolean }>`
  cursor: pointer;

  color: #51525f;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  p {
    font-size: 14px;
  }

  ${(props) =>
    props.open
      ? css`
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        `
      : css`
          border-radius: 8px;
        `}
`

export const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
`

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: block;
  overflow: auto;
  max-height: ${(props) => (props.isOpen ? '500px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease;
  animation: ${(props) => (props.isOpen ? slideDown : 'none')} 0.5s ease;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const AccordionDetails = styled.div`
  padding: 0 16px 16px 16px;
`

export const GridContainer = styled.div<{ columns: number }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 16px;
`

export const GridItem = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: end;
`
