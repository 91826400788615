export function cpfMask(cpf: string) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
}

export function phoneMask(phone?: string): string {
  if (!phone) return ''

  const sanitizedPhone = phone.replace(/\D/g, '')

  if (sanitizedPhone.length > 11) {
    return sanitizedPhone
      .replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4')
  }

  return sanitizedPhone
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}
