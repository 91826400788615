import styled, { css } from 'styled-components'
import { Button } from 'vlab-frontend-components'

interface BackgroundProps {
  backgroundUrl?: string
}

interface ButtonProps {
  backgroundColor?: string
  borderColor?: string
  textColor?: string
}

export const Background = styled.div<BackgroundProps>`
  background-color: #475d74;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ backgroundUrl }) => css`
    background-image: url(${backgroundUrl}),
      ${`url(https://patient-portal-assets.vlab.live/patient_vlab_live/background.png)`};
  `}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '0166FF')};
  border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : '0166FF')};
  color: ${({ textColor }) => (textColor ? textColor : 'white')};
`

export const Logo = styled.img`
  max-width: 100px;
  max-height: 100px;
`

export const LoginBox = styled.div`
  background: white;
  padding: 40px;
  opacity: 0.9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const CheckboxContainer = styled.div`
  text-align: left;
  margin-top: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 18px auto;
  grid-template-rows: 1fr;
  gap: 8px;

  #consent,
  #terms {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    #consent,
    #terms {
      width: 18px;
      height: 18px;
    }
  }

`

export const CheckboxLabel = styled.label`
  font-size: 12px;
  color: #666;
`

export const Link = styled.a`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
`
