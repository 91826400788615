
const { REACT_APP_ENV } = process.env

console.log(REACT_APP_ENV)

const S3_URLS = {
    staging: 'https://stg-patient-portal-assets.vlab.live/',
    development: 'https://stg-patient-portal-assets.vlab.live/',
    production: 'https://patient-portal-assets.vlab.live/'
}

const S3_BUCKETS_FOLDER_DEFAULT = {
    staging: 'vlabportalpatient_vlab_live',
    localhost: 'vlabportalpatient_vlab_live',
    production: 'resultados_vlab_live'
}

export const S3_URL = REACT_APP_ENV ? S3_URLS[REACT_APP_ENV] : ""
export const S3_BUCKET_DEFAULT = REACT_APP_ENV ? S3_BUCKETS_FOLDER_DEFAULT[REACT_APP_ENV] : ""


const getS3UrlByWindowLocation = (file) => {
    const hostnameDashed = window.location.hostname.replace(/[.]/g, '_')

    if (window.location.hostname === "localhost" || window.location.hostname === "local.vlab.live") {
        return `${S3_URL}${hostnameDashed}/${file}`
    }

    return `${S3_URL}${hostnameDashed}/${file}`
}


export const getS3LogoUrl = async () => await getS3UrlByWindowLocation('logo.png')
export const getS3BackgroundUrl = async () => await getS3UrlByWindowLocation('background.png')
export const getS3SettingsUrl = async () => {

    const url = getS3UrlByWindowLocation('settings.json')

    return await fetch(url)
}

export const getConfig = async () => {
    const url = S3_URL + 'orgRoutes.json'
    console.log('Fetching URL:', url)
    return await fetch(url)
}