import { format } from 'date-fns'
import { Typography } from 'vlab-frontend-components'
import { Card, CardContainer, ImageContainer } from './styles'
import { MedicalReports } from '../../../../types/medicalReports'
import { ClientConfig } from '../../../../types/clientConfig'

interface ReportCardProps {
  report: MedicalReports
  onClickCard: () => void
  clientConfig: ClientConfig
}

export default function ReportCard({ report, onClickCard, clientConfig }: ReportCardProps) {
  return (
    <CardContainer onClick={() => onClickCard()}>
      <Card>
        <ImageContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="95"
            viewBox="0 0 62 62"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.125 7.25C0.125 3.31497 3.31497 0.125 7.25 0.125H42.4556C46.3906 0.125 49.5806 3.31497 49.5806 7.25V54.7496C49.5806 58.6847 46.3906 61.8746 42.4556 61.8746H7.25C3.31497 61.8746 0.125 58.6847 0.125 54.7496V7.25ZM7.25 4.875C5.93833 4.875 4.875 5.93832 4.875 7.25V54.7496C4.875 56.0613 5.93832 57.1246 7.25 57.1246H42.4556C43.7673 57.1246 44.8306 56.0613 44.8306 54.7496V7.25C44.8306 5.93832 43.7673 4.875 42.4556 4.875H7.25Z"
              fill={clientConfig.patient_portal_settings.menu.menuItemBackground || '#5f5f5f'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.4189 19.916C12.4189 15.981 15.6089 12.791 19.5439 12.791H23.4556C27.3907 12.791 30.5806 15.981 30.5806 19.916V23.8277C30.5806 27.7627 27.3907 30.9527 23.4556 30.9527H19.5439C15.6089 30.9527 12.4189 27.7627 12.4189 23.8277V19.916ZM19.5439 17.541C18.2323 17.541 17.1689 18.6043 17.1689 19.916V23.8277C17.1689 25.1394 18.2323 26.2027 19.5439 26.2027H23.4556C24.7673 26.2027 25.8306 25.1394 25.8306 23.8277V19.916C25.8306 18.6043 24.7673 17.541 23.4556 17.541H19.5439Z"
              fill={clientConfig.patient_portal_settings.menu.menuItemBackground || '#5f5f5f'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.1244 30.209H45.7152V25.459H57.6367C59.9771 25.459 61.8744 27.3563 61.8744 29.6967V41.6182C61.8744 52.806 52.8049 61.8754 41.6172 61.8754V57.1254C50.1816 57.1254 57.1244 50.1826 57.1244 41.6182V30.209Z"
              fill={clientConfig.patient_portal_settings.menu.menuItemBackground || '#5f5f5f'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.2152 39.709H13.3036V34.959H36.2152V39.709Z"
              fill={clientConfig.patient_portal_settings.menu.menuItemBackground || '#5f5f5f'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.2152 49.207H13.3036V44.457H36.2152V49.207Z"
              fill={clientConfig.patient_portal_settings.menu.menuItemBackground || '#5f5f5f'}
            />
          </svg>
          <Typography
            color="blackLight"
            type="poppinsBodyS"
            style={{ fontWeight: 600, fontSize: '24px', marginTop: '16px' }}
          >
            Resultados
          </Typography>
        </ImageContainer>

        <Typography
          color="blackLight"
          type="poppinsBodyS"
          style={{ fontWeight: 600, fontSize: '12px' }}
        >
          Dia {format(new Date(report?.created_at), 'dd/MM/yyyy')}
        </Typography>

        <Typography
          color="blackLight"
          type="poppinsBodyS"
          style={{ fontWeight: 600, fontSize: '12px' }}
        >
          {report.identifier}
        </Typography>
        <Typography color="black" type="poppinsBodyS" style={{ fontSize: '10px' }}>
          {report.organization_description}
        </Typography>
      </Card>
    </CardContainer>
  )
}
