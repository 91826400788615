import React from 'react'
import * as Vlab from 'vlab-frontend-components'

type Props = {
  open: boolean
  percentage: number
  status: string
  error: boolean
  setOpen: (value: boolean) => void
}
const StatusDownloadDialog: React.FC<Props> = ({
  open,
  percentage,
  error,
  status,
  setOpen,
}: Props) => {
  return (
    <div id="minhaDiv" className="ignore-click-outside download-file">
      <Vlab.Dialog
        isOpen={open}
        onClose={() => setOpen(false)}
        containerStyle={{
          zIndex: 99999,
        }}
        contentStyle={{
          width: '400px',
        }}
        title="Download do MP4"
      >
        <Vlab.Typography color="greyDark" type="poppinsBodyL">
          {status}
        </Vlab.Typography>
        {!error && (
          <div style={{ width: '100%' }}>
            <Vlab.ProgressBar percent={percentage} />
          </div>
        )}
      </Vlab.Dialog>
    </div>
  )
}

export { StatusDownloadDialog }
