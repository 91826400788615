import axios from 'axios'
import { Login } from '../types/login'
import { ExamsData, Media, ScreenshotsData } from '../types/exams'
import { MedicalReportsData } from '../types/medicalReports'
import { Results } from '../types/Results'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export async function login(data: Login) {
  const response = await axios.post(process.env.REACT_APP_API_URL + '/login', data)
  return response.data
}

export function useApi() {
  async function getExams(page: number = 0) {
    const response = await axiosInstance.get(`/exam?page=${page}`)
    return response.data as ExamsData
  }

  async function getExamsVod(id?: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/exam/${id}/vod.m3u8`)
    return response.data as string
  }

  async function getExamsImageIa(id: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/exam/${id}/ia`)
    return response.data as Media
  }

  async function getMedicalReports(page: number = 0, pageSize: number) {
    const response = await axiosInstance.get(`/medicalreport?page=${page}&page_size=${pageSize}`)
    return response.data as MedicalReportsData
  }

  async function getResult(id: string | null): Promise<Results[]> {
    if (!id) return []
    const response = await axiosInstance.get(`/medicalreport/${id}/result`)
    return response.data
  }

  async function getResultFiles(reportId: string, resultId: string) {
    const response = await axiosInstance.get(`/medicalreport/${reportId}/result/${resultId}`)
    return response.data
  }

  async function getMedicalReportById(id: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/medicalreport/${id}`)
    return response.data as MedicalReportsData
  }

  async function getMp4Exam(id: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/exam/${id}/mp4`)
    window.open(response.data.mp4_url, '_blank')
  }
  async function getDownloadExam(id: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/exam/${id}/download`)
    return response.data
  }

  async function getExamScreenshots(id: string | null) {
    if (!id) return
    const response = await axiosInstance.get(`/exam/${id}/screenshots`)
    return response.data as ScreenshotsData
  }

  return {
    getExams,
    getExamsVod,
    getMedicalReports,
    getMedicalReportById,
    getMp4Exam,
    getExamScreenshots,
    getResult,
    getExamsImageIa,
    getResultFiles,
    getDownloadExam,
  }
}
