import styled from "styled-components";


export const StyledButtonRounded = styled.button`

  display: flex;
  border: none;
  width: 26px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;

  background-color: transparent;

  transition: all 0.3s;

  &:hover {
    background-color: #F7F8FC !important;
  }

  cursor: pointer;
`

export const SytledDotButton = styled(StyledButtonRounded)`

  background-color: #F7F8FC !important;

  cursor: default;
`

