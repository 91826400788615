import styled from "styled-components"



export const Opacity = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
`

export const Content = styled.div`
  
`

export const Header = styled.div`
  height: 69px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 24px;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 16px;
`

export const Wrapper = styled.div`
  padding:  24px;
`

export const VideoContent = styled.div`
    width: 100%;
    margin-bottom: 24px;
`

export const VideoWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 56.25%;
  height: 0px;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  background-color: black;
`