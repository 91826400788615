import { CircularProgress, Dialog, DownloadIcon, PDFViewer, Typography } from 'vlab-frontend-components'

import AccordionResult from '../AccordionResult'
import { ResultFiles, Results } from '../../../../types/Results'
import { useEffect, useState } from 'react'
import {
  AccordionHeader,
  AccordionWrapper,
  EmptyContainer,
  ImageWrapper,
  ResultContainer,
  Wrapper,
} from './styles'
import VideoRender from '../VideoRender'
import FlexContainer from 'vlab-frontend-components/dist/cjs/components/FlexContainer/FlexContainer'
import { useApi } from '../../../../hooks/useAPi'
import { StatusDownloadDialog } from '../../../../components/StatusDownloadDialog'
import { MedicalReports } from '../../../../types/medicalReports'
import { format } from 'date-fns'
import JSZip from "jszip";
import { LoadingContainer } from '../../../../components/LoadingContainer'

interface ModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  results: Results[]
  selectedReport: MedicalReports | null
}

interface ImageViewerProps {
  src: string
  alt?: string
  width?: string
  height?: string
}

export interface AllItems {
  url: string
  filename: string
  file_type: number
}

const ImageViewer = ({ src, alt = '', width = '100%', height = 'auto' }: ImageViewerProps) => (
  <ImageWrapper height={height} width={width}>
    <img src={src} alt={alt} />
  </ImageWrapper>
)

export default function Modal({ open, setOpen, results, selectedReport }: ModalProps) {
  const [selectedFile, setSelectedFile] = useState<Partial<ResultFiles> | null>(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedsResults, setSelectedsResults] = useState<any[]>([])
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const [downloadStatus, setDownloadStatus] = useState('')
  const [percentageVideoDownload, setPercentageVideoDownload] = useState(0)
  const [downloadError, setDownloadError] = useState(false)
  const [allItems, setAllItems] = useState<AllItems[]>([])
  const { getDownloadExam } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])



  const downloadFilesHelper = async (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }



  function zipNameByReport(): string {
    if (!selectedReport) return '';
    if (selectedReport.iam_user_name) {
      return `${selectedReport.iam_user_name}_${format(new Date(selectedReport.created_at), "dd_MM_yyyy")}.zip`;
    } else {
      return `${selectedReport.identifier}_${format(new Date(selectedReport.created_at), "dd_MM_yyyy")}.zip`;
    }
  }

  const downloadFiles = async () => {
    if (allItems.length === 1) {
      const singleFile = allItems[0];
      const response = await fetch(singleFile.url);

      if (!response.ok) {
        throw new Error("Erro ao baixar o arquivo");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = singleFile.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } else if (allItems.length > 1) {
      const zip = new JSZip();
      setIsLoading(true);

      const downloadPromises = allItems.map(async (file) => {
        const response = await fetch(file.url);

        if (!response.ok) {
          throw new Error("Erro ao baixar o arquivo");
        }

        const blob = await response.blob();
        zip.file(file.filename, blob);
      });

      await Promise.all(downloadPromises);

      zip.generateAsync({ type: "blob" }).then((content) => {
        const zipUrl = window.URL.createObjectURL(content);
        const zipLink = document.createElement("a");
        zipLink.href = zipUrl;
        zipLink.download = zipNameByReport();
        document.body.appendChild(zipLink);
        zipLink.click();
        document.body.removeChild(zipLink);
        window.URL.revokeObjectURL(zipUrl);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  return (
    <Dialog
      isOpen={open}
      onClose={() => setOpen(false)}
      title="Resultados"
      contentStyle={{
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        maxWidth: '90vw',
        maxHeight: '90vh',
        position: "relative"
      }}
    >

      {isLoading && (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: "0", left: "0", background: "white", opacity: "0.8" }}>
          <CircularProgress style={{ width: '50px', height: '50px' }} />
        </div>
      )}
      <StatusDownloadDialog
        open={openDownloadDialog}
        setOpen={(value) => {
          setOpenDownloadDialog(value)
        }}
        percentage={percentageVideoDownload}
        status={downloadStatus}
        error={downloadError}
      />
      <Wrapper>
        <ResultContainer>
          <AccordionWrapper>
            <AccordionHeader>
              <FlexContainer alignItems="center" gap="8px">
                <input
                  type="checkbox"
                  checked={selectedsResults.length > 0}
                  onClick={() => {
                    if (selectedsResults.length > 0) {
                      setSelectedsResults([])
                    } else {
                      setSelectedsResults(allItems)
                    }
                  }}
                />
                {selectedsResults.length > 0 && (
                  <Typography color="black" type="poppinsBodyS" style={{ fontWeight: 300 }}>
                    {selectedsResults.length} selecionados
                  </Typography>
                )}
              </FlexContainer>

              <div
                style={{ cursor: selectedsResults.length > 0 ? 'pointer' : 'not-allowed' }}
                onClick={() => {
                  if (selectedsResults.length > 0) {
                    downloadFiles()
                  }
                }}
              >
                <DownloadIcon />
              </div>
            </AccordionHeader>
            {results.map((result) => (
              <>
                <AccordionResult
                  result={result}
                  key={result.id}
                  setSelectedFile={setSelectedFile}
                  setSelectedsResults={setSelectedsResults}
                  selectedsResults={selectedsResults}
                  setAllItems={setAllItems}
                />
              </>
            ))}
          </AccordionWrapper>
          <div style={{ width: !selectedFile ? '60%' : 'fit-content' }}>
            {!selectedFile && (
              <EmptyContainer>
                <Typography color="black" type="poppinsBodyL" style={{ fontWeight: 300 }}>
                  Selecione um arquivo para visualizar
                </Typography>
              </EmptyContainer>
            )}

            {selectedFile && selectedFile.file_type === 1 && (
              <PDFViewer
                file={selectedFile.url || ''}
                height={600}
                width={windowSize <= 870 ? 300 : 700}
              />
            )}
            {selectedFile && selectedFile.file_type === 2 && (
              <ImageViewer src={selectedFile.url || ''} height="100%" width="100%" />
            )}
            {selectedFile && selectedFile.file_type === 3 && (
              <div
                style={{
                  width: windowSize <= 870 ? 300 : 600,
                  height: '100%',
                }}
              >
                <VideoRender
                  openReport={true}
                  selectedReport={{
                    exam: selectedFile.url || '',
                  }}
                />
              </div>
            )}
          </div>
        </ResultContainer>
      </Wrapper>
    </Dialog>
  )
}
