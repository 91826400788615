import React, { useEffect, useRef, useState } from 'react';
import { BlurNamePlugin } from './plugins/blur-name';
import { ClapprIntro } from './plugins/clappr-intro';


import { Player, Events } from 'clappr';

import styled from 'styled-components';
import { useMixpanel } from '../../hooks/useMixpanel';

type ClapprProps = {
  examId: string
  m3u8Url: string
  introUrl: string
  id: string
  autoplay?: boolean
  muted?: boolean
  blurName?: boolean
  mp4Url: string
  organization: string
  fallBackAudioUrl: string
  previewName?: string
  onlyMp4?: boolean
};

const Clappr: React.FC<ClapprProps> = ({
  examId,
  m3u8Url,
  introUrl,
  autoplay,
  id,
  blurName,
  mp4Url,
  fallBackAudioUrl,
  previewName,
  onlyMp4,
  organization
}: ClapprProps) => {
  const playerRef = useRef<any | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const finishPlayerRef = useRef<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { trackEvent } = useMixpanel()

  const stringToBoolean = (str: string): boolean | undefined => {
    if (str === 'true') {
      return true;
    }
    return false;
  };

  const playNextVideo = ({ player, ended }: { player: any, ended?: boolean, error?: boolean }) => {

    console.log('finishPlayerRef.current', finishPlayerRef.current)
    if (ended && finishPlayerRef.current) {

      return
    }

    player.load({ source: onlyMp4 ? mp4Url : m3u8Url });
    player.once(Events.PLAYER_ERROR, () => {
      player.load({ source: mp4Url });
      player.play();
    });

    player.play();
    finishPlayerRef.current = true;
  };


  useEffect(() => {
    const initPlayer = async () => {
      const hideIntro = false


      const accessToken = localStorage.getItem('jwt');
      const hlsjsConfig = {
        xhrSetup: (xhr: any) => {
          xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        },
        nudgeOffset: 4,
        nudgeMaxRetry: 60,
        liveSyncDurationCount: 9,
        maxMaxBufferLength: 12,
        debug: '_LIVE_MODE_',
      };

      if (!onlyMp4) {
        try{
          const response = await fetch(`${process.env.REACT_APP_API_URL}/exam/${examId}/vmusic-token`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          })
          const responseString = await response.text()
          m3u8Url = `${process.env.REACT_APP_MUSIC_URL}/vlab/m3u8/${examId}/${responseString}/vod.m3u8`
        } catch (e) {
          console.log(e)
        }
      }


      let source = onlyMp4 ? mp4Url : m3u8Url;

      if (!hideIntro && introUrl !== '') {
        if (onlyMp4) {
          source = introUrl
          finishPlayerRef.current = false
        } else {
          try {
            const response = await fetch(introUrl, {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
              }
            })

            const responseJson = await response.json()
            source = responseJson
          } catch (error) {
            console.log(error)
          }
        }
      } else {
        finishPlayerRef.current = true;
      }

      if (fallBackAudioUrl) {
        audioRef.current = new Audio(`${fallBackAudioUrl}`);
        audioRef.current.loop = true;
      }

      let seekCountToPlayAudio = 0;
      playerRef.current = new Player({
        parentId: `#clappr-${id}`,
        source,
        hlsjsConfig,
        width: '100%',
        height: '100%',
        autoPlay: autoplay,
         playback: {
          playInline: true,
        },
        events: {
          onEnded: () => playNextVideo({ player: playerRef.current!, ended: true, error: false }),
          onError: (error: any) => {
            playNextVideo({ player: playerRef.current!, ended: false, error: true })

            trackEvent("WEB_PATIENT_ERROPLAYVIDEO", {
              examId: examId,
              error: error,
              isVlabStaff: organization.toLocaleLowerCase() === 'vlab'
            })
          },
          onPlay: () => {
            if (audioRef.current && finishPlayerRef.current) {
              audioRef.current.play();
              audioRef.current.volume = playerRef.current?.muted
                ? 0
                : playerRef.current?.getVolume() / 100;
            }

            trackEvent("WEB_PATIENT_PLAYEXAM", {
              examId: examId,
              isVlabStaff: organization.toLocaleLowerCase() === 'vlab'
            })
          },
          onPause: () => {
            if (audioRef.current) {
              audioRef.current.pause();
            }
          },
          onStop: () => {
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current.currentTime = 0;
            }
          },
          onVolumeUpdate: (event: number) => {
            if (audioRef.current) {
              audioRef.current.volume = event / 100;
            }
          },
          onSeek: (event: number) => {
            if (audioRef.current) {
              let seekTo = event;
              if (event > audioRef.current.duration) {
                seekTo = event % audioRef.current.duration;
              }
              audioRef.current.currentTime = seekTo;
              audioRef.current.pause();
              seekCountToPlayAudio = 2;
            }
          },
          onTimeUpdate: () => {
            switch (seekCountToPlayAudio) {
              case 2:
                seekCountToPlayAudio--;
                break;
              case 1:
                seekCountToPlayAudio--;
                if (audioRef && audioRef.current) {
                  audioRef.current.play();
                }
                break;
            }
          },
        },
      });
    };

    initPlayer();

    return () => {
      playerRef.current?.destroy();
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [m3u8Url, introUrl, id, blurName, mp4Url, fallBackAudioUrl, onlyMp4]);

  return (
    <StyledContainerPlayer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {previewName &&
        <VideoNameLayer isHovered={isHovered}>
          <p>{previewName}</p>
          {showDialog && (
            <DialogBox>
              <p>Prévia de vídeo com a música selecionada</p>
            </DialogBox>
          )}
        </VideoNameLayer>
      }

      <VideoFull id={`clappr-${id}`} style={{ width: '100%' }}></VideoFull>
    </StyledContainerPlayer>
  );
}

export {
  Clappr
};

const VideoFull = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
`;

const StyledContainerPlayer = styled.div`
    position: relative;
    box-sizing: border-box;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
    background-color: black;
`;

const VideoNameLayer = styled.div<{ isHovered: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  padding: 16px;
  z-index: 99;
  text-align: start;
  opacity: ${(props) => (props.isHovered ? 1 : 0)};
  visibility: ${(props) => (props.isHovered ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: var(--System-White, #F7F8FC);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
`;


const DialogBox = styled.div`
  position: absolute;
  right: 48px;
  background: var(--System-Black-Light, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #51525F);  
  padding: 8px;
  border-radius: 4px;
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  width: 134px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p {
    color: #FFF;
    text-align: right;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
`;