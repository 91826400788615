import { format } from "date-fns"
import { Typography } from "vlab-frontend-components"
import { Card, CardContainer, Image, ImageContainer } from "./styles"
import { Exam } from "../../../../types/exams"
// import card from "../../../../images/card.png"
interface ExamCardProps {
  exam: Exam
  onClickCard: () => void
  image: string
}


export default function ExamCard({ exam, onClickCard, image }: ExamCardProps) {

  return (
    <CardContainer onClick={() => onClickCard()}>
      <Card>
        <ImageContainer>
          <Image src={image} alt="#" />
        </ImageContainer>

        <Typography color="blackLight" type="poppinsBodyS" style={{ fontWeight: 600, fontSize: "12px" }}>
          Dia {format(new Date(exam?.created_date), 'dd/MM/yyyy')}
        </Typography>
        <Typography color="blackLight" type="poppinsBodyS" style={{ fontWeight: 600, fontSize: "12px" }}>
          {exam.organization_unit_room}
        </Typography>
        <Typography color="blackLight" type="poppinsBodyS" style={{ fontWeight: 600, fontSize: "12px" }}>
          {exam.identifier}
        </Typography>
        <Typography color="black" type="poppinsBodyS" style={{ fontSize: "10px" }}>
          {exam.organization_unit}
        </Typography>
      </Card>

    </CardContainer>
  )
}

