import { ExpandedImage, Gallery, ImageCarousel } from 'vlab-frontend-components'

interface ScreenshotGalleryProps {
  open: boolean
  setOpen: (open: boolean) => void
  listOfScreenshots: {
    thumb: string
    type: string
  }[]
  selectedScreenShot: {
    thumb: string
    index: number
  }
  setSelectedScreenShot: (selectedScreenShot: any) => void
}

export default function ScreenshotGallery({
  open,
  setOpen,
  listOfScreenshots,
  selectedScreenShot,
  setSelectedScreenShot,
}: ScreenshotGalleryProps) {
  return (
    <Gallery isOpen={open} onClose={() => setOpen(false)}>
      <ExpandedImage src={selectedScreenShot?.thumb ? selectedScreenShot.thumb : ''} />

      <ImageCarousel
        //@ts-ignore
        thumbs={listOfScreenshots}
        //@ts-ignore
        selected={selectedScreenShot}
        onSelected={(value) => setSelectedScreenShot(value)}
        onNext={(index) => {
          setSelectedScreenShot({ ...listOfScreenshots[index + 1], index: index + 1 })
        }}
        onPrevious={(index) => {
          setSelectedScreenShot({ ...listOfScreenshots[index - 1], index: index - 1 })
        }}
      />
    </Gallery>
  )
}
