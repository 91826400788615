import styled from "styled-components"
import { Clappr } from "../../../../components/Clappr"
import FlexContainer from "../../../../components/Flex"
import { Typography } from "vlab-frontend-components"


export const VideoWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 56.25%;
  height: 0px;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  background-color: black;
`

export default function VideoRender({ selectedReport, openReport }) {


  return (
    <VideoWrapper>
      {selectedReport?.exam && openReport ? (
        <Clappr
          fallBackAudioUrl={""}
          id={selectedReport.exam}
          introUrl={""}
          m3u8Url={`${process.env.REACT_APP_API_URL}/exam/${selectedReport.exam}/vod.m3u8`}
          mp4Url={""}
          organization={selectedReport.organization}
          examId={selectedReport.exam}
          autoplay={false}
          blurName={true}
          muted={false}
          key={selectedReport.id}
        // onlyMp4={true}
        />
      ) : (
        <FlexContainer alignItems='center' justifyContent='center' height='288px' direction='column' gap="4px">
          <Typography color="white" type="buttonS">Sem laudo</Typography>
          <Typography color="greyDark" type="buttonS" >Não há documentos associados a este laudo.</Typography>
        </FlexContainer>
      )}
    </VideoWrapper>
  )
}