import { CircularProgress, } from 'vlab-frontend-components'
import { useEffect, useState } from 'react'
import FlexContainer from '../../components/Flex'
import { Opacity, ViewTypeWrapper } from './style'
import { useApi } from '../../hooks/useAPi'
import { useQuery } from 'react-query'
import { MedicalReports } from '../../types/medicalReports'
import Pagination from '../../components/Pagination'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import ReportCard from './components/Card'
import { LoadingContainer } from '../../components/LoadingContainer'
import GreetingByTime from '../../components/GreetingByTime'
import { useAtom } from 'jotai'
import { clientConfigAtom } from '../Login'
import { getS3BackgroundUrl, getS3LogoUrl } from '../../utils/s3'
import { useMixpanel } from '../../hooks/useMixpanel'
import Modal from './components/Modal'
import { Results } from '../../types/Results'
import ListItem from './components/ListItem'
import { CiCircleList } from "react-icons/ci";
import { PiSquaresFourThin } from "react-icons/pi";
export interface MedicalReportsData extends MedicalReports {
  report_url_pdf: string
}

export default function Exams() {
  const [openReport, setOpenReport] = useState(false)
  const [selectedReport, setSelectedReport] = useState<MedicalReports | null>(null)
  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10 })
  const [clientConfig, setClientConfig] = useAtom(clientConfigAtom)
  const [results, setResults] = useState<Results[]>([])
  const { getMedicalReports, getResult } = useApi()
  const { data, isLoading } = useQuery(['medicalReports', pagination.currentPage - 1, pagination.pageSize], () =>
    getMedicalReports(pagination.currentPage - 1, pagination.pageSize), {
    refetchOnWindowFocus: false,
  })

  const { trackEvent } = useMixpanel()
  const [isListView, setIsListView] = useState(false)

  async function getResults(reportId: string) {
    const response = await getResult(reportId)

    if (response) {
      return response
    }
  }

  async function handleCardClick(report: MedicalReports) {
    const resultsData = await getResults(report.id)
    if (resultsData && resultsData.length > 0) {
      setResults(resultsData)
    }
    setSelectedReport(report)
    setOpenReport(true)
  }

  async function getClientConfig() {
    await getS3LogoUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_logo: url,
      }))
    })
    await getS3BackgroundUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_background: url,
      }))
    })
  }

  useEffect(() => {
    trackEvent('WEB_PATIENT_LISTRESULTS', {
      resultsAmount: data?.total_items.toString(),
    })
  }, [])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    getClientConfig()
  }, [])



  return (
    <div>
      {openReport && (
        <Opacity
          onClick={() => {
            setOpenReport(false)
          }}
        />
      )}

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ width: '50px', height: '50px' }} />
        </LoadingContainer>
      ) : (
        <>
          <GreetingByTime />

          <FlexContainer gap='8px' margin='0 0 16px 0' width='100%' justifyContent='flex-end'>

            <ViewTypeWrapper style={{ backgroundColor: !isListView ? "#e0e0e0" : "" }} onClick={() => {
              setIsListView(false)
              setPagination({
                currentPage: 1,
                pageSize: 10,
              })
            }}>
              <PiSquaresFourThin size="24px" />
            </ViewTypeWrapper>

            <ViewTypeWrapper onClick={() => {
              setIsListView(true)
              setPagination({
                currentPage: 1,
                pageSize: 20,
              })
            }} style={{ backgroundColor: isListView ? "#e0e0e0" : "" }}>
              <CiCircleList size="24px" />
            </ViewTypeWrapper>
          </FlexContainer>
          {isListView ? (

            <FlexContainer direction='column' gap='8px'>
              {data?.items.map((report) => (
                <ListItem
                  key={report.id}
                  report={report}
                  onClickCard={async () => await handleCardClick(report)} />
              ))}

            </FlexContainer>
          ) : (

            <FlexContainer gap={'16px'} wrap="wrap" width="100%" justifyContent="center">
              {data?.items.map((report) => (
                <ReportCard
                  key={report.id}
                  report={report}
                  onClickCard={async () => await handleCardClick(report)}
                  clientConfig={clientConfig}
                />
              ))}
            </FlexContainer>
          )}



          {openReport && <Modal open={openReport} setOpen={setOpenReport} results={results} selectedReport={selectedReport} />}

          <Pagination
            total={data?.total_items || 0}
            disabled={false}
            currentPage={data?.current_page || 0}
            pageSize={data?.page_size || 0}
            totalPages={data?.total_pages || 0}
            onPageChange={(page) =>
              setPagination((previous) => ({ ...previous, currentPage: page }))
            }
          />
        </>
      )}
    </div>
  )
}
