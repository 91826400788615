import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const Image = styled.img`
  object-fit: cover;
  max-height: 200px;
  border-radius: 8px;
  width: 100%;
  background-color: #ecf7fd;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  min-width: 250px;
  border-radius: 8px;
  flex-direction: column;
`
