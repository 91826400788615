import { GridItem, Text, TextPhone } from "vlab-frontend-components";
import Accordion from "../../../../components/Accordion";
import { GridContainer } from "../../../../components/Accordion/style";
import { format } from "date-fns";
import { Exam } from "../../../../types/exams";

interface AccordionExamProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedExam: Exam;
}

export default function AccordionExam({ open, setOpen, selectedExam }: AccordionExamProps) {


  return (
    <Accordion openAccordion={open} setOpenAccordion={setOpen} title="Exame">
      <div style={{ display: 'flex', marginTop: '16px' }}>
        <GridContainer
          columns={2}
        >
          <GridItem>
            <div style={{ textAlign: 'start' }}>
              <TextPhone
                value={selectedExam.phone}
                description='Telefone'
              />
            </div>
          </GridItem>

          <GridItem>
            <Text description='Empresa' text={selectedExam.organization} />
          </GridItem>

          <GridItem>
            <Text description='Unidade' text={selectedExam.organization_unit} />
          </GridItem>

          <GridItem>
            <Text description='Sala' text={selectedExam.organization_unit_room} />
          </GridItem>

          <GridItem>
            <Text description='Data e horário' text={format(new Date(selectedExam.created_date), 'dd/MM/yyyy HH:mm')} />
          </GridItem>

        </GridContainer>
      </div>
    </Accordion>
  )
}