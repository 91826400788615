import styled from "styled-components"
import FlexContainer from "../../../../components/Flex"
import { MedicalReports } from "../../../../types/medicalReports"
import { format } from "date-fns"
import { phoneMask } from "../../../../utils/masks"

const Container = styled.div`
  width: 100%;

  &:hover {	
      background-color: #f5f5f5;	
  }
  transition: background-color 0.2s;

`

const Column = styled.div`
   cursor: pointer ;
    width: 100%;
    padding: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #000000;
    border: 1px solid #e1e1e1;
    border-right: none;

    &:last-child {
      border-right: 1px solid #e1e1e1;
      border-radius: 0 5px 5px 0;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }


    @media (max-width: 768px) {
      font-size: 12px;
    }
`

interface ListItemProps {
  report: MedicalReports
  onClickCard: () => void
}

export default function ListItem({
  report,
  onClickCard
}: ListItemProps) {
  return (
    <Container onClick={onClickCard}>
      <FlexContainer width="100%" >
        <Column style={{ maxWidth: "40%" }}>{report?.identifier || report?.iam_user_name}</Column>
        <Column style={{ maxWidth: "20%" }}>{format(new Date(report?.created_at), 'dd/MM/yyyy')}</Column>
        <Column style={{ maxWidth: "20%" }}>{report.organization}</Column>
        <Column style={{ maxWidth: "20%" }}>{phoneMask(report?.phone_number)}</Column>
      </FlexContainer>
    </Container>
  )
}