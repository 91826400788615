import { useEffect, useState } from 'react'
import { CircularProgress, Header, HeaderItem, Typography } from 'vlab-frontend-components'
import SidebarComponent from '../SidebarComponent'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import FlexContainer from '../Flex'
import { getS3BackgroundUrl, getS3LogoUrl, getS3SettingsUrl } from '../../utils/s3'
import { useAtom } from 'jotai'
import { clientConfigAtom } from '../../pages/Login'
import { LoadingContainer } from '../LoadingContainer'

const Content = styled.div<{ isSidebarOpen: boolean }>`
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '250px' : '68px')};
  padding: 24px;
  transition: all 0.5s ease;
  padding-top: 78px;
`

const config = {
  '/exams': {
    title: 'Ultrassons',
  },
  '/medical-reports': {
    title: 'Resultados',
  },
}

export default function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [headerName, setHeaderName] = useState('exams')
  const location = useLocation()
  const [isLoading, setLoading] = useState(true)
  const [clientConfig, setClientConfig] = useAtom(clientConfigAtom)

  useEffect(() => {
    getClientConfig()
  }, [])

  async function getClientConfig() {
    setLoading(true)
    await getS3LogoUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_logo: url,
      }))
    })
    await getS3BackgroundUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_background: url,
      }))
    })

    await getS3SettingsUrl()
      .then(async (url) => {
        const settingsJson = await url.json()

        setClientConfig((prev) => ({
          ...prev,
          settings: settingsJson,
        }))
      })
      .catch((error) => {
        console.log(error)
      })
    setLoading(false)
  }

  if (isLoading) {
    return (
      <LoadingContainer style={{ height: '50vh' }}>
        <CircularProgress style={{ width: '50px', height: '50px' }} />
      </LoadingContainer>
    )
  }
  return (
    <div className="App">
      <Header
        position="left"
        open={isSidebarOpen}
        style={{
          backgroundColor:
            clientConfig.patient_portal_settings.pageStyle.header &&
            clientConfig.patient_portal_settings.pageStyle.header,
          borderBottom: `1px solid ${clientConfig.patient_portal_settings.pageStyle.header && clientConfig.patient_portal_settings.pageStyle.header}`,
        }}
      >
        <HeaderItem style={{ width: '100%' }}>
          <FlexContainer justifyContent="space-between" width="100%" alignItems="center">
            <Typography
              color="white100"
              type="subheadingS"
              style={{
                color:
                  clientConfig.patient_portal_settings.pageStyle?.headerTitleColor &&
                  clientConfig.patient_portal_settings.pageStyle?.headerTitleColor,
              }}
            >
              {config[location.pathname]?.title || config[headerName]?.title}
            </Typography>
          </FlexContainer>
        </HeaderItem>
      </Header>

      <SidebarComponent isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

      <Content isSidebarOpen={isSidebarOpen}>
        <Outlet />
      </Content>
    </div>
  )
}
