import styled from "styled-components"

interface FlexContainerProps {
  direction?: 'row' | 'column'
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  wrap?: 'no-wrap' | 'wrap' | 'wrap-reverse'
  gap?: string
  width?: string
  height?: string
  margin?: string
  padding?: string
  backgroundColor?: string
  borderRadius?: string
  border?: string
  boxShadow?: string
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'
  top?: string
  left?: string
  right?: string
  bottom?: string
  zIndex?: string
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto'
  cursor?: 'auto' | 'pointer' | 'text' | 'wait' | 'crosshair' | 'move' | 'not-allowed'
  transition?: string
  transform?: string
  hoverTransform?: string
  activeTransform?: string
  focusTransform?: string
  disabledTransform?: string

}


const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-wrap: ${({ wrap }) => wrap || 'no-wrap'};
  gap: ${({ gap }) => gap || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  border: ${({ border }) => border || 'none'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  position: ${({ position }) => position || 'static'};
  top: ${({ top }) => top || 'auto'};
  left: ${({ left }) => left || 'auto'};
  right: ${({ right }) => right || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  z-index: ${({ zIndex }) => zIndex || 'auto'};
  overflow: ${({ overflow }) => overflow || 'visible'};
  cursor: ${({ cursor }) => cursor || 'auto'};
  transition: ${({ transition }) => transition || 'none'};
  transform: ${({ transform }) => transform || 'none'};
  &:hover {
    transform: ${({ hoverTransform }) => hoverTransform || 'none'};
  }
  &:active {
    transform: ${({ activeTransform }) => activeTransform || 'none'};
  }
  &:focus {
    transform: ${({ focusTransform }) => focusTransform || 'none'};
  }
  &:disabled {
    transform: ${({ disabledTransform }) => disabledTransform || 'none'};
  }

`

export default FlexContainer